import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/javascript/es6/es6-parameter-handling",
  "date": "2017-05-30",
  "title": "WEB APPLICATION SECURITY",
  "author": "Sean Amarasinghe",
  "tags": ["development", "javascript", "es6"],
  "featuredImage": "feature.jpg",
  "excerpt": "ES6 introduced improved parameter handling in JavaScript. Instead of the half array arguments object, these new improvements will significantly improve how we handle arguments and parameters."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Spread Operator`}</h2>
    <p>{`In ES5 we had to pass in the context to a function with apply method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var array = [2, 3, 5];
Math.min.apply(Math, array); // 2
`}</code></pre>
    <p>{`ES6 introduced in the `}<inlineCode parentName="p">{`spread`}</inlineCode>{` operator, which expands the array to create individual values for the function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var array = [2, 3, 5];
Math.min(...array); // 2
`}</code></pre>
    <p>{`Spread operator can also be used with other paramters, multiple times as well as mixed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function func() {
  console.log(arguments);
}
 
const parameters = [3, 4, 8];
func(1, ...parameters, 11, ...[15, 20]);
`}</code></pre>
    <p>{`Spread operator is also works well with constructors.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`new Date(...[1980, 2, 15]);
// Sat Mar 15 1980 00:00:00 GMT+1000 (AEST)
`}</code></pre>
    <h2>{`Rest Parameters`}</h2>
    <p>{`Rest parameters somewhat does the opposite of the spread operator. It collects parameters and turns them into an array just like the arguments half array.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function func(...rest) {
  console.log(rest);
}
 
func('a', 'b', 1, 2);
// ["a", "b", 1, 2]
`}</code></pre>
    <p>{`Rest parameters improve the readability of the code immensely.`}</p>
    <Message type="warn" title="Rest Limitations" content="Rest paramater has to be the last one and you can’t have more than one." mdxType="Message" />
    <h2>{`Default Parameters`}</h2>
    <p>{`ES6 now allows default parameters in the function signature.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function func(a = 1, b = 2) {
  console.log(a, b);
}
 
func(2); // 2 2
func(); // 1 2
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`ES6 parameter handling has come a long way since the last version. These improvements will make parameter handling in JavaScript a breeze.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      